import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "filtering-is-the-mechanism-by-which-a-user-adds-or-removes-data-items-from-a-displayed-data-set-by-turning-on-and-off-certain-predefined-attributes"
    }}><em parentName="h3">{`Filtering`}</em>{` is the mechanism by which a user adds or removes data items from a displayed data set by turning on and off certain predefined attributes.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Selecting filters</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Triggering filters</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Filter interaction</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "selecting-filters"
    }}>{`Selecting filters`}</h2>
    <h3 {...{
      "id": "single-selection"
    }}>{`Single selection`}</h3>
    <p>{`A `}<em parentName="p">{`single-selection filter`}</em>{` is employed when the user can pick `}<strong parentName="p">{`only one attribute`}</strong>{` from a category to modify the data results. Under the hood, single selection behaves like a radio button. Ways in which a single selection filter can manifest include:`}</p>
    <ul>
      <li parentName="ul">{`Basic dropdown`}</li>
      <li parentName="ul">{`Inline dropdown`}</li>
      <li parentName="ul">{`Search`}</li>
      <li parentName="ul">{`Radio button set (either standalone or within a menu)`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.18478260869565%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "single selection filters",
        "title": "single selection filters",
        "src": "/static/ae4c48e02583d7e4e96476c6b1be77fb/fb070/filter-1.png",
        "srcSet": ["/static/ae4c48e02583d7e4e96476c6b1be77fb/d6747/filter-1.png 288w", "/static/ae4c48e02583d7e4e96476c6b1be77fb/09548/filter-1.png 576w", "/static/ae4c48e02583d7e4e96476c6b1be77fb/fb070/filter-1.png 1152w", "/static/ae4c48e02583d7e4e96476c6b1be77fb/c3e47/filter-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "multi-selection"
    }}>{`Multi-selection`}</h3>
    <p>{`A `}<em parentName="p">{`multi-selection filter`}</em>{` is employed when the user has the option to pick `}<strong parentName="p">{`more than one attribute`}</strong>{` from a category to modify the data results. Under the hood, multi-selections behave like `}<em parentName="p">{`checkboxes`}</em>{`. Ways in which a single selection filter can manifest include:`}</p>
    <ul>
      <li parentName="ul">{`Multi-select dropdown`}</li>
      <li parentName="ul">{`Inline multi-select dropdown`}</li>
      <li parentName="ul">{`Search with tags`}</li>
      <li parentName="ul">{`Checkbox set (either standalone or within a menu)`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.18478260869565%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "multi-selection filters",
        "title": "multi-selection filters",
        "src": "/static/b02bd2ecd58c44ce11dadeedd0112835/fb070/filter-2.png",
        "srcSet": ["/static/b02bd2ecd58c44ce11dadeedd0112835/d6747/filter-2.png 288w", "/static/b02bd2ecd58c44ce11dadeedd0112835/09548/filter-2.png 576w", "/static/b02bd2ecd58c44ce11dadeedd0112835/fb070/filter-2.png 1152w", "/static/b02bd2ecd58c44ce11dadeedd0112835/c3e47/filter-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "multiple-categories"
    }}>{`Multiple categories`}</h3>
    <p>{`A `}<em parentName="p">{`category`}</em>{` is a set of filter items within the same topic. For example, “size” is the category and `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`, and `}<inlineCode parentName="p">{`extra large`}</inlineCode>{` are the filter choices. Multiple filter categories may be applied to the same data set. For example, the user can filter by size as well as color and price range.`}</p>
    <p>{`Multiple category selection is most commonly manifested in either a vertical list on the left side of the page or within a horizontal drawer at the top of the data set. Multiple categories should never be put within a menu or dropdown.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "76.22282608695652%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Multiple filter categories examples",
        "title": "Multiple filter categories examples",
        "src": "/static/aeb30ee6a2088dc356072b4aabf47f37/fb070/filter-3.png",
        "srcSet": ["/static/aeb30ee6a2088dc356072b4aabf47f37/d6747/filter-3.png 288w", "/static/aeb30ee6a2088dc356072b4aabf47f37/09548/filter-3.png 576w", "/static/aeb30ee6a2088dc356072b4aabf47f37/fb070/filter-3.png 1152w", "/static/aeb30ee6a2088dc356072b4aabf47f37/c3e47/filter-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "triggering-filters"
    }}>{`Triggering filters`}</h2>
    <h3 {...{
      "id": "batch-filter"
    }}>{`Batch filter`}</h3>
    <p>{`A `}<em parentName="p">{`batch filter`}</em>{` is employed when all filters are applied together at the end of the selection process. The data set only refreshes once. The trigger is most commonly an “Apply filter” button.`}</p>
    <p>{`The batch filter works best when the user is making several filtering selections across different categories that may take a longer time to mentally process. Batch filtering is also a good solution for slow data-return speeds. This can prevent the user from having to wait for the data to load after every selection.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "55.57065217391304%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Batch filter example",
        "title": "Batch filter example",
        "src": "/static/626cfe08ee74806c6fcbe31561d7017c/fb070/filter-4.png",
        "srcSet": ["/static/626cfe08ee74806c6fcbe31561d7017c/d6747/filter-4.png 288w", "/static/626cfe08ee74806c6fcbe31561d7017c/09548/filter-4.png 576w", "/static/626cfe08ee74806c6fcbe31561d7017c/fb070/filter-4.png 1152w", "/static/626cfe08ee74806c6fcbe31561d7017c/c3e47/filter-4.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "interactive-filter"
    }}>{`Interactive filter`}</h3>
    <p>{`An `}<em parentName="p">{`interactive filter`}</em>{` returns results after each individual selection is made. The trigger is the individual selection and the filter manipulates the data in real time. This is a good solution for when the user is only selecting from one category or the user is expected to only make one filter selection.`}</p>
    <h2 {...{
      "id": "filter-interaction"
    }}>{`Filter interaction`}</h2>
    <p>{`Each filter interaction should contain the following patterns and states:`}</p>
    <ul>
      <li parentName="ul">{`Default start state`}</li>
      <li parentName="ul">{`Unselected and selected state`}</li>
      <li parentName="ul">{`Filter applied state`}</li>
      <li parentName="ul">{`Clear filter(s) action`}</li>
    </ul>
    <h3 {...{
      "id": "starting-states"
    }}>{`Starting states`}</h3>
    <p>{`Filters within each category should start either as `}<em parentName="p">{`all unselected`}</em>{` or `}<em parentName="p">{`all selected`}</em>{`. When using multiple categories, the start state can vary from category to category. If the user wants only one or a few criteria to be excluded from the results, then all filters should be selected at the start. Vice-versa, if the user wants to see only results related to one particular criteria, then all filters should start as unselected.`}</p>
    <h3 {...{
      "id": "filter-applied"
    }}>{`Filter applied`}</h3>
    <p>{`If the filter(s) can be hidden in either a drawer, dropdown, or menu, then there should be an indicator visible on the closed filter state that informs the user that filters have been applied. At a minimum, the indicator should include the number of filters applied and have the option to clear filters without re-opening the filter container.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "59.91847826086957%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Hidden filters not applied",
        "title": "Hidden filters not applied",
        "src": "/static/cc9e37db91f2eeb07520d441f0bee16d/fb070/filter-5.png",
        "srcSet": ["/static/cc9e37db91f2eeb07520d441f0bee16d/d6747/filter-5.png 288w", "/static/cc9e37db91f2eeb07520d441f0bee16d/09548/filter-5.png 576w", "/static/cc9e37db91f2eeb07520d441f0bee16d/fb070/filter-5.png 1152w", "/static/cc9e37db91f2eeb07520d441f0bee16d/c3e47/filter-5.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "59.91847826086957%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Hidden filters applied",
        "title": "Hidden filters applied",
        "src": "/static/408f7f7427d3688ce4a00c50da5f73ca/fb070/filter-6.png",
        "srcSet": ["/static/408f7f7427d3688ce4a00c50da5f73ca/d6747/filter-6.png 288w", "/static/408f7f7427d3688ce4a00c50da5f73ca/09548/filter-6.png 576w", "/static/408f7f7427d3688ce4a00c50da5f73ca/fb070/filter-6.png 1152w", "/static/408f7f7427d3688ce4a00c50da5f73ca/c3e47/filter-6.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "resetting-filters"
    }}>{`Resetting filters`}</h3>
    <p>{`Each category should have a way to clear all applied filters at once without having to interact with each individual item. Clearing filters returns the filters to their original default starting state.`}</p>
    <p>{`If multiple categories have been applied to the same data set then there should be a way to dismiss all filters across all categories at once.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "31.38586956521739%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Filter reset example",
        "title": "Filter reset example",
        "src": "/static/b75426a8be0d165c445392b646eb0002/fb070/filter-7.png",
        "srcSet": ["/static/b75426a8be0d165c445392b646eb0002/d6747/filter-7.png 288w", "/static/b75426a8be0d165c445392b646eb0002/09548/filter-7.png 576w", "/static/b75426a8be0d165c445392b646eb0002/fb070/filter-7.png 1152w", "/static/b75426a8be0d165c445392b646eb0002/c3e47/filter-7.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      